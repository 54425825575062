import Form from "../components/Form";
import Sidebar from "../components/Sidebar";


const Home = () => {
    return(
        <>
            <Sidebar />
            <Form />
        </>
    )
}

export default Home;
const Step2 = () => {
    return(
<div className="multisteps-form__panel" data-animation="slideHorz">
					<div className="wizard-forms">
						<div className="inner pb-100 clearfix">
							<div className="form-content pera-content">
								<div className="step-inner-content">
									<span className="step-no bottom-line">Step 2</span>
									<div className="step-progress float-end">
										<span>2 of 5 completed</span>
										<div className="step-progress-bar">
											<div className="progress">
												<div className="progress-bar"></div>
											</div>
										</div>
									</div>

									<h2>Basic Information</h2>
									<p>Please fill in your information to enable us serve you better. </p>
									
									<div className="form-inner-area">
										<input type="text" name="fullname" className="form-control required" minLength="2" placeholder="First and last name *" required/>
										<input type="email" name="email" className="form-control required" placeholder="Email Address *" required id="email"/>
										<input type="text" name="phone" placeholder="Phone Number" required/>
									</div>
									<div className="gender-selection">
										<h3>Gender:</h3>
										<label>
											<input type="radio" name="gender" value="Male" defaultChecked/>
											<span className="checkmark"></span>Male
										</label>
										<label>
											<input type="radio" name="gender" value="Female"/>
											<span className="checkmark"></span>Female
										</label>
									</div>
								</div>
							</div>
						</div>
						<div className="actions">
							<ul>
								<li><span className="js-btn-prev" title="BACK"><i className="fa fa-arrow-left"></i> BACK </span></li>
								<li><span className="js-btn-next" title="NEXT">NEXT <i className="fa fa-arrow-right"></i></span></li>
							</ul>
						</div>
					</div>
				</div>
    )   
}
export default Step2;
const Sidebar = () => {
    return (
            <div className="steps-area steps-area-fixed">
			<div className="image-holder">
				<img src="/assets/img/edit.jpg" alt=""/>
			</div>
			<div className="logo" style={{position:"absolute",top: "5px",justifyContent: "center",alignItems:"center",display: "flex",width: "100%"}}>
				<img src="/assets/img/logowhite.png" alt="" style={{width:"100px",height: "80px"}}/>
			</div>
			<div className="steps clearfix">
				<ul className="tablist multisteps-form__progress">
					<li className="multisteps-form__progress-btn js-active current">
						<span>1</span>
					</li>
					<li className="multisteps-form__progress-btn">
						<span>2</span>
					</li>
					<li className="multisteps-form__progress-btn">
						<span>3</span>
					</li>
					<li className="multisteps-form__progress-btn">
						<span>4</span>
					</li>
					<li className="multisteps-form__progress-btn last">
						<span>5</span>
					</li>
				</ul>
			</div>
		</div>
    )
}


export default Sidebar;
import { useEffect,  useRef, useState } from "react";

const Step1 = () => {
    useEffect(() => {
      $(document).ready(function(){
        $("#exampleModal").modal('show');
    });
  })

  function closeModal() {
    $("#exampleModal").modal('hide');
  }

  return (
    <>
      <div
        className="multisteps-form__panel js-active"
        data-animation="slideHorz"
      >
        <div className="wizard-forms">
          <div className="inner pb-100 clearfix">
            <div className="form-content pera-content">
              <div className="step-inner-content">
                <span className="step-no">Step 1</span>
                <h2>
                  Unleash Your Potential Today: For As Low As{" "}
                  <span className="text-success"> ₦2700</span> Your Perfect
                  Workspace is Just a Few Clicks Away.
                </h2>
                <p>
                  Our office space offers everything you need to excel in your
                  work, from high-speed internet to comfortable seating. Choose
                  a plan and unlock your potential today.
                </p>
                <div className="btn-space">
                    <button
                      type="button"
                      className="btn btn-primary btn-color mt-3 btn-lg"
                      data-toggle="modal"
                      data-target="#exampleModal"
                      // onClick={() => modalRef.current && modalRef.current.show()}
                    >
                      View price list
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary btn-color mt-3 btn-lg"
                    >
                      <a href="https://maps.app.goo.gl/iq7MziQJX7QV2i4q9" target="_blank">
                        Get Direction
                      </a>
                    </button>
                </div>
                <div className="step-box">
                  <h1 style={{ textAlign: "center" }}>Choose A Plan</h1>
                  <div className="row">
                    <div className="col-md-4">
                      <label className="step-box-content bg-white text-center relative-position active">
                        <span className="step-box-icon">
                          <img src="/assets/img/d1.png" alt="" />
                        </span>
                        <span className="step-box-text">Daily Plan</span>
                        <span className="service-check-option">
                          <span>
                            <input
                              type="radio"
                              name="plan"
                              className="plantime"
                              value="daily"
                              defaultChecked
                            />
                          </span>
                        </span>
                      </label>
                    </div>
                    <div className="col-md-4">
                      <label className="step-box-content bg-white text-center relative-position">
                        <span className="step-box-icon">
                          <img src="/assets/img/d2.png" alt="" />
                        </span>
                        <span className="step-box-text">Weekly Plan</span>
                        <span className="service-check-option">
                          <span>
                            <input
                              type="radio"
                              name="plan"
                              className="plantime"
                              value="weekly"
                            />
                          </span>
                        </span>
                      </label>
                    </div>
                    <div className="col-md-4">
                      <label className="step-box-content bg-white text-center relative-position">
                        <span className="step-box-icon">
                          <img src="/assets/img/d3.png" alt="" />
                        </span>
                        <span className="step-box-text">Monthly Plan</span>
                        <span className="service-check-option">
                          <span>
                            <input
                              type="radio"
                              className="plantime"
                              name="plan"
                              value="monthly"
                            />
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="actions d-flex justify-content-center align-items-center">
            <ul className="d-flex justify-content-center align-items-center">
              <li className="disable" aria-disabled="true">
                <span className="js-btn-next" title="NEXT">
                  Backward <i className="fa fa-arrow-right"></i>
                </span>
              </li>
              <li>
                <span className="js-btn-next" title="NEXT">
                  NEXT <i className="fa fa-arrow-right"></i>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
     
      {/* modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header float-right">
              <h5>CO-Working Space(WITHOUT DATA)</h5>
              <div className="text-right">
              </div>
            </div>
            <div className="modal-body">
              <div>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Time</th>
                      <th scope="col">Price </th>
                      {/* <th scope="col">Price(old)</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>Daily</td>
                      <td>2,700</td>
                      {/* <td>1,400</td> */}
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Weekly</td>
                      <td>13,000</td>
                      {/* <td>6,500</td> */}
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td>Monthly</td>
                      <td>50,000</td>
                      {/* <td>25,000</td> */}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header float-right">
              <h5>CO-Working Space(WITH DATA)</h5>
              <div className="text-right">
              </div>
            </div>
            <div className="modal-body">
              <div>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Time</th>
                      <th scope="col">Price</th>
                      {/* <th scope="col">Price(old)</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>Daily</td>
                      <td>4,000</td>
                      {/* <td>2,000</td> */}
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Weekly</td>
                      <td>18,000</td>
                      {/* <td>9,500</td> */}
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td>Monthly</td>
                      <td>70,000</td>
                      {/* <td>37,000</td> */}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header float-right">
              <h5>TRAININGS and MEETING ROOMS</h5>
              <div className="text-right">
              </div>
            </div>
            <div className="modal-body">
              <div>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Time</th>
                      <th scope="col">Price</th>
                      {/* <th scope="col">Price(old)</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>Daily</td>
                      <td>11,000</td>
                      {/* <td>1,400</td> */}
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Weekly</td>
                      <td>50,000</td>
                      {/* <td>6,500</td> */}
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td>Monthly</td>
                      <td>180,000</td>
                      {/* <td>25,000</td> */}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Step1;

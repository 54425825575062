const Step5 = () => {
    return(
<div className="multisteps-form__panel" data-animation="slideHorz">
					<div className="wizard-forms">
						<div className="inner pb-100 clearfix">
							<div className="form-content pera-content">
								<div className="step-inner-content">
									<span className="step-no bottom-line">Step 5</span>
									<div className="step-progress float-end">
										<span>5 of 5 completed</span>
										<div className="step-progress-bar">
											<div className="progress">
												<div className="progress-bar" style={{width:"100%"}}></div>
											</div>
										</div>
									</div>
									
									<h2>Please, Make Your Payment</h2>
									<p>kindly proceed with your payment to continue</p>
									<input type="text" id="amount" name="amount" hidden/>
									<div className="step-content-area">
										<button id="pay-btn" type="button" style={{height:"50px",width:"150px",color: "white",backgroundColor: "#6b59d3",border: "none",borderRadius: "2%"}}>Pay now</button>
									</div>
								</div>
							</div>
						</div>
						
						<div className="actions">
							<ul>
								<li><span className="js-btn-prev" title="BACK"><i className="fa fa-arrow-left"></i> BACK </span></li>
							</ul>
						</div>
					</div>
				</div>
    )   
}
export default Step5;
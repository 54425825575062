import Packages from "./Packages";

const Step3 = () => {
    return(
<div className="multisteps-form__panel" data-animation="slideHorz">
					<div className="wizard-forms">
						<div className="inner pb-100 clearfix">
							<div className="form-content pera-content">
								<div className="step-inner-content">
									<span className="step-no bottom-line">Step 3</span>
									<div className="step-progress float-end">
										<span>3 of 5 completed</span>
										<div className="step-progress-bar">
											<div className="progress">
												<div className="progress-bar" style={{width:"40%"}}></div>
											</div>
										</div>
									</div>
									<h2>What kind of services Do You Need</h2>
									<p>Amongst the variety of services we offer, choose a specific package you would want to enjoy.</p>
									<Packages />
								</div>
							</div>
						</div>
						<div className="actions">
							<ul>
								<li><span className="js-btn-prev" title="BACK"><i className="fa fa-arrow-left"></i> BACK </span></li>
								<li><span className="js-btn-next" title="NEXT">NEXT <i className="fa fa-arrow-right"></i></span></li>
							</ul>
						</div>
					</div>
				</div>
    )   
}
export default Step3;
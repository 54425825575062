const Packages = () => {
    return (
        <div className="step-content-field">
										<div className="plan-area">
										<div className="plan-icon-text text-center active pkg">
												<div className="plan-icon">
													<i className="fas fa-cubes"></i>
												</div>
												<div className="plan-text">
													<h3>Basic Package <span id="basic" style={{fontSize: 19}}></span></h3>
													<ul style={{fontSize: 15}}>
                                                        <li> <i className="fas fa-check text-success mr-1"></i>Workspace</li>
                                                        <li><i className="fas fa-check text-success mr-1"></i>Power Supply</li>
														<li><i className="fas fa-times text-danger mr-1"></i>Internet Access</li>
                                                    </ul>
													<input type="radio" name="package" value="basic" checked=""/>
												</div>
											</div>
											<div className="plan-icon-text text-center pkg">
												<div className="plan-icon">
													<i className="fas fa-cubes"></i>
												</div>
												<div className="plan-text">
													<h3>General Packages <span id="general" style={{fontSize: 19}}></span></h3>
													<ul style={{fontSize: 15}}>
                                                        <li><i className="fas fa-check text-success mr-1"></i>Workspace</li>
                                                        <li><i className="fas fa-check text-success mr-1"></i>Power Supply</li>
                                                        <li><i className="fas fa-check text-success mr-1"></i>Internet Access</li>
                                                    </ul>
													<input type="radio" name="package" value="general" />
												</div>
											</div>
											<div className="plan-icon-text text-center pkg">
												<div className="plan-icon">
													<i className="fas fa-chess-queen"></i>
												</div>
												<div className="plan-text">
													<h3>Private Office <br /> <span id="private" style={{fontSize: 19}}></span></h3>
                                                    <ul style={{fontSize: 15}}>
                                                        <li><i className="fas fa-check text-success mr-1"></i>Private Office</li>
                                                        <li><i className="fas fa-check text-success mr-1"></i>Power Supply</li>
                                                        <li><i className="fas fa-check text-success mr-1"></i>Internet Access</li>
                                                    </ul>
													<input type="radio" name="package" value="private"/>
												</div>
											</div>
										</div>
									</div>
    )
}

export default Packages;
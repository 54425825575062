import "../assets/assets/css/bootstrap.min.css"
import "../assets/assets/css/fontawesome-all.css"
import "../assets/assets/css/style.css"
const Thanks = () => {
    if(!sessionStorage.getItem('paysuccess')){
        return window.location.href = "/"
    }
    return(
    <section id="thank-you" class="thank-you-section">
			<div class="container">
				<div class="thank-you-wrapper position-relative thank-wrapper-style-one thank-wrapper-style-three">
					<div class="thank-txt text-center">
						<h1>You have successfully booked a workspace</h1>
						<div class="thank-icon">
							<img src="assets/img/tht3.png" alt=""/>
						</div>
						<p className="pb-2">You’ll receive an email with your Booking information. For more enquiries, contact us using our contact information below.</p>
                        <span className="mt-0"><img src="assets/img/eni.png" alt=""/> hello@abiatechhub.com</span>
                        <span className="mt-0 ms-3"><img src="assets/img/phi.png" alt=""/> +2348052749600</span>
						<a class="d-block text-center text-uppercase mt-2" href="/">back to page</a>
					</div>
				</div>
			</div>
		</section>
    )
}
export default Thanks;
import Step1 from "./form-components/Step1";
import Step2 from "./form-components/Step2";
import Step3 from "./form-components/Step3";
import Step4 from "./form-components/Step4";
import Step5 from "./form-components/Step5";
import {useParams} from 'react-router-dom';
import { useEffect } from "react";
const Form = () => {
    let params = useParams();
    useEffect(() =>{
        var ref = ""
        if (params.ref){
            ref = params.ref
        }
        document.getElementById("ref").value = ref;
    })
    return(
        <form className="multisteps-form__form" action="/api/book" id="wizard" method="POST">
            <div className="form-area position-relative">
                <Step1 />
                <Step2 />
                <Step3 />
                <Step4 />
                <Step5 />
                <input type="text" name="ref" id="ref" hidden/>
            </div>
        </form>
    )
}

export default Form;
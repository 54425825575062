import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import $ from 'jquery';
import Home from './pages/Home';
import "./assets/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/css/fontawesome-all.css";
import "./assets/css/style.css";
import "./assets/assets/js/bootstrap.min.js";
import { useEffect } from 'react';
import Thanks from './pages/Thanks';

function App() {
  useEffect(() => {
      // This variable will save the event for later use.
      let deferredPrompt;
      window.addEventListener('beforeinstallprompt', (e) => {
      // Prevents the default mini-infobar or install dialog from appearing on mobile
      e.preventDefault();
      // Save the event because you'll need to trigger it later.
      deferredPrompt = e;
      deferredPrompt.prompt();
  });
  })
  return (
    <Router>
        <Routes>
            <Route path="/" element={<Home />}/>
            <Route path="/ref/:ref" element={<Home />}/>
            <Route path="/thanks" element={<Thanks />}/>
        </Routes>
    </Router>
  );
}

export default App;
